import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/Status_LED/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import StatusTable from 'components/Web_User_Interface/1440p_Series/Features/Status_LED/statusTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Status LED",
  "path": "/Web_User_Interface/1440p_Series/Features/Status_LED/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.",
  "image": "./WebUI_1440p_SearchThumb_Features_Status_LEDs.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Status LED' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Did you notice the blue and blinking LED on the camera? It can be very annoying in a dark room. But it can be used to troubleshoot your network connection or to make people aware that they are being recorded. For all other purposes come here to deactivate it.' image='/images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_Status_LEDs.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/Status_LED/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/Status_LED/' crumbLabel="Status LED" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7a7fb455832157c33422c6d762e01b94/2e367/1440p_Settings_Features_Status_LED.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACCUlEQVQoz2XQy04TUQCH8b4HGzS29I7RxEDnPtMpLbTTOWd6gVIuWrlErCYYgQKRxOjC4E43mrjQhLAysEBXJOjGBTEmPoGP8plpQ4i4+OXM4uTL/0wkm82STqdJZzLEo9cwWlvIF6dUdo4obR8z0bti65hS74jZ/W8Y7V10ZYxMdpShoSGGh4eJJJNJQqlUiuiN61hTTVrd5zRWd2is7hIs9agtb/8j6DylubxJMVgkN6YwPq5y+9advkgYuhCNxTB1hVZD0pAeNb+MqJQGvEmEN4UoTyCb8wT3N/E8ga7n0VQb08ijKublwlA0GsU0LVqzbeqNJkJIpAwQIb+K8D38SonK2kv8V2fUZlYo2mVMw0LXbHI5nUgikeivuwya1Ot1hBBIKQdR4SNn7iEXHzM9vchDz2fFr3FXNmnLKq5rYmgOSs4YBC8WxmIxNE3D932q1SpC+ASBQFQryM4G8skb5hfW2S8U2LNdunOS7rJLsWCgqRaaciU4+E4wOnoT23RxnTzTzRKTpTxF18JUx3FMnalyge1nPY4P3tJdcLAMHde2MDTt8snhGRoZGSGVTPf/SahYdHHzJpZp0Zlr8GjtAZ8+fuD3rx/8OTvl3foKhp6j4Fioqvb/wng8TiqV6ccM3cKxXRzLZmmhzeH71xydfOHn+XfOPh9w/vWEw70N5ITWv6sqOn8Bo1pX1G0Qie0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a7fb455832157c33422c6d762e01b94/e4706/1440p_Settings_Features_Status_LED.avif 230w", "/en/static/7a7fb455832157c33422c6d762e01b94/d1af7/1440p_Settings_Features_Status_LED.avif 460w", "/en/static/7a7fb455832157c33422c6d762e01b94/7f308/1440p_Settings_Features_Status_LED.avif 920w", "/en/static/7a7fb455832157c33422c6d762e01b94/730d0/1440p_Settings_Features_Status_LED.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7a7fb455832157c33422c6d762e01b94/a0b58/1440p_Settings_Features_Status_LED.webp 230w", "/en/static/7a7fb455832157c33422c6d762e01b94/bc10c/1440p_Settings_Features_Status_LED.webp 460w", "/en/static/7a7fb455832157c33422c6d762e01b94/966d8/1440p_Settings_Features_Status_LED.webp 920w", "/en/static/7a7fb455832157c33422c6d762e01b94/fbb05/1440p_Settings_Features_Status_LED.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a7fb455832157c33422c6d762e01b94/81c8e/1440p_Settings_Features_Status_LED.png 230w", "/en/static/7a7fb455832157c33422c6d762e01b94/08a84/1440p_Settings_Features_Status_LED.png 460w", "/en/static/7a7fb455832157c33422c6d762e01b94/c0255/1440p_Settings_Features_Status_LED.png 920w", "/en/static/7a7fb455832157c33422c6d762e01b94/2e367/1440p_Settings_Features_Status_LED.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7a7fb455832157c33422c6d762e01b94/c0255/1440p_Settings_Features_Status_LED.png",
              "alt": "Web User Interface - 1440p Series - Features Status LED",
              "title": "Web User Interface - 1440p Series - Features Status LED",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <StatusTable mdxType="StatusTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Here you can adjust the behaviour of the red Power Status LED and blue WiFi Status LED of the camera. Enable it to have it display camera activity - which is especially helpful during the installation process. Or deactivate it for normal operation.`}</p>
    <p>{`In normal operation, only the red LED flashes and the blue LED is either on (`}<strong parentName="p">{`WiFi connection`}</strong>{`) or off (`}<strong parentName="p">{`LAN connection`}</strong>{`). In addition, when the `}<strong parentName="p">{`WiFi connection`}</strong>{` is interrupted, the blue one starts to blink rapidly.`}</p>
    <h2 {...{
      "id": "camera-reset--wps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#camera-reset--wps",
        "aria-label": "camera reset  wps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Camera Reset & WPS`}</h2>
    <p>{`Pressing the reset button on your camera will turn the Status LEDs off and...`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`After 3s`}</strong>{`: The blue status LEDs turn on and, after releasing the button, the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1440p_Series/Network/WiFi/"
        }}>{`Wireless-Protected-Setup`}</a>{` mode or `}<strong parentName="li">{`WPS`}</strong>{` is active, which allows you to add your camera to your local WiFi. The blue LED will then start flashing slowly, indicating that the camera is trying to connect via WPS. Once successfully connected, the blue LED will be solid. If an existing WiFi connection is interrupted, the blue LED would blink rapidly.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 15s`}</strong>{`: The blue status LED starts flashing slowly and, after releasing the button, all camera settings `}<strong parentName="li">{`with the exception of the network configuration`}</strong>{` are reset.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 20s`}</strong>{`: The blue status LED starts to flash rapidly and `}<strong parentName="li">{`ALL`}</strong>{` camera settings are reset when the button is released.`}</li>
      <li parentName="ol"><strong parentName="li">{`After 30s`}</strong>{`: The blue status LED goes out and the camera is reset to the state before the last firmware update. The camera then restarts.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      